export const QUERY = {
  MEMBER: {
    CUSTOMER: (option = {}) => {
      const { itId = null, agentId = null, providerType = null, enabled = null, locked = null, searchKey = null } = option;
      return { itId, agentId, providerType, enabled, locked, searchKey };
    },
    BANK_CARD: (option = {}) => {
      const { itId = null, agentId = null, searchKey = null } = option;
      return { itId, agentId, searchKey };
    },
    IDENTIFICATION: (option = {}) => {
      const { itId = null, agentId = null, customerId = null, approvalStatus = null, searchKey = null } = option;
      return { itId, agentId, customerId, approvalStatus, searchKey };
    },
    CUSTOMER_VERIFY_FLOW: (option = {}) => {
      const { itId = null, agentId = null, customerId = null, searchKey = null, approvalStatus = null } = option;
      return { itId, agentId, customerId, searchKey, approvalStatus };
    },
  },
  CASH: {
    IT_RECEIVE: (option = {}) => {
      const { itId = null, searchKey = null } = option;
      return { itId, searchKey };
    },
    USDT_ERC20_CONFIG: (option = {}) => {
      const { itId = null } = option;
      return { itId };
    },
    USDT_TRC20_CONFIG: (option = {}) => {
      const { itId = null } = option;
      return { itId };
    },
    FX_RATE_TEMPLATE: (option = {}) => {
      const { itId = null, searchKey = null } = option;
      return { itId, searchKey };
    },
    CUSTOMER_DEPOSIT_FLOW: (option = {}) => {
      const {
        itId = null,
        agentId = null,
        customerId = null,
        searchKey = null,
        processed = null,
        paymentOrderStatus = null,
      } = option;
      return { itId, agentId, customerId, searchKey, processed, paymentOrderStatus };
    },
    CUSTOMER_WITHDRAWAL_FLOW: (option = {}) => {
      const {
        itId = null,
        agentId = null,
        customerId = null,
        approvalStatus = null,
        processed = null,
        paymentOrderStatus = null,
        searchKey = null,
        duration = null,
        durationType = null,
        hasRemainingAmount = null,
      } = option;
      return {
        itId,
        agentId,
        customerId,
        approvalStatus,
        processed,
        paymentOrderStatus,
        searchKey,
        duration,
        durationType,
        hasRemainingAmount,
      };
    },
    CUSTOMER_TRANSFER: (option = {}) => {
      const {
        itId = null,
        agentId = null,
        senderRole = null,
        depositCustomerId = null,
        receiverRole = null,
        withdrawalCustomerId = null,
        statuses = null,
      } = option;
      return { itId, agentId, senderRole, depositCustomerId, receiverRole, withdrawalCustomerId, statuses };
    },
    PAYMENT_CHANNEL: (option = {}) => {
      const { paymentChannel = null, paymentGateway = null, payType = null, transactionCategory = null } = option;
      return { paymentChannel, paymentGateway, payType, transactionCategory };
    },
    PAYMENT_GATEWAY: (option = {}) => {
      const { itId = null, paymentGateway = null } = option;
      return { itId, paymentGateway };
    },
  },
  ROLE: {
    USER: (option = {}) => {
      const { itId = null, agentId = null, searchKey = null, roleTypes = null } = option;
      return { itId, agentId, searchKey, roleTypes };
    },
  },
  SYSTEM: {
    CACHE: (option = {}) => {
      const { name = null } = option;
      return { name };
    },
  },
};
